import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { Navbar } from "../components/Navbar";
import Footer from "../components/Footer";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { IoMdArrowRoundUp } from "react-icons/io";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

gsap.registerPlugin(ScrollTrigger);

export const Gallary = () => {

  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useGSAP(() => {
    gsap.fromTo(
      "#hero",
      {
        opacity: 0,
        y: -150,
      },
      {
        opacity: 1,
        y: 0,
        delay: 1.0,
      }
    );
  }, []);

  useGSAP(() => {
    gsap.fromTo(
      "#hero1",
      {
        opacity: 0,
        y: -150,
      },
      {
        opacity: 1,
        y: 0,
        delay: 1.2,
      }
    );
  }, []);
    useGSAP(() => {
        gsap.fromTo(
          "#text00",
          {
            opacity: 0,
            y: -150,
          },
          {
            opacity: 1,
            y: 0,
            delay: 1.8,
          }
        );
      }, []);

      useGSAP(() => {
        gsap.fromTo(
          "#text001",
          {
            opacity: 0,
            y: -150,
          },
          {
            opacity: 1,
            y: 0,
            delay: 2.2,
          }
        );
      }, []);

    const images = [
        "https://via.placeholder.com/300",
        "https://via.placeholder.com/300",
        "https://via.placeholder.com/300",
        "https://via.placeholder.com/300",
        "https://via.placeholder.com/300",
        "https://via.placeholder.com/300",
        "https://via.placeholder.com/300",
        "https://via.placeholder.com/300",
        "https://via.placeholder.com/300",
        "https://via.placeholder.com/300",
        "https://via.placeholder.com/300",
        "https://via.placeholder.com/300",
      ];
  return (
    <Box
      sx={{
        overflowX: "hidden",
        backgroundColor: "whitesmoke",
        color: "#113",
        fontFamily: "math",
      }}
    >
      <Helmet>
        <title>Lyss Technology Pvt. Ltd. | Gallary</title>
      </Helmet>
      <Navbar />

      <Box
        sx={{
          marginTop: "70px",
          width: "100vw",
          textAlign: "center",
          backgroundImage: `url(../images/gallery.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          paddingTop: { xs: "8vw", lg: "2vw", md: "2vw" },
          paddingBottom: "15vw",
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.6)", // Overlay with opacity
            zIndex: 1,
          },
        }}
      >
        <Grid
          container
          sx={{
            position: "relative",
            zIndex: 2,
            color: "white",
            padding: { xs: "20px", sm: "20px", md: "50px" },
          }}
        >
          <Grid item xs={12} sm={12} lg={6} md={6}>
            <Typography
              variant="h1"
              sx={{
                fontSize: {
                  xs: "2rem",
                  sm: "2.4rem",
                  md: "2.6rem",
                  lg: "2.6rem",
                },
                marginTop: { xs: "20px", md: "80px" },
                fontWeight: "bold",
              }}
              id='hero'
            >
              Lyss Technology Gallery
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1.1rem",
                  md: "1.2rem",
                  lg: "1.2rem",
                },
                marginTop: "10px",
                fontWeight: "500",
                padding: { xs: "10px", sm: "10px", md: "0px" },
              }}
              id='hero1'
            >
              You'll find a collection of images that showcase
              our innovative projects, cutting-edge technology, and vibrant work environment.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} md={6}></Grid>
        </Grid>
      </Box>

      {scrollPosition > 150 && (
          <Button
            id="button"
            sx={{
              position: "fixed",
              bottom: "20px",
              right: "10px",
              zIndex: "10",
              fontSize: "1.5rem",
              backgroundColor:"#7a7ad4",
              borderRadius: "59px",
            }}
            onClick={(e) => {
              window.scrollTo({
                top: "0px",
                behavior: "smooth",
              });
            }}
          >
            <IoMdArrowRoundUp style={{color:"white"}}/>
          </Button>
        )}
     
     <Box
        
      >
        {scrollPosition > 150 && (
          <Button
            id="button"
            sx={{
              position: "fixed",
              bottom: "20px",
              right: "10px",
              zIndex: "10",
              fontSize: "1.5rem",
              backgroundColor: "#7a7ad4",
            }}
            onClick={(e) => {
              window.scrollTo({
                top: "0px",
                behavior: "smooth",
              });
            }}
          >
            <IoMdArrowRoundUp style={{ color: "white" }} />
          </Button>
        )}
       
      </Box>

      <Box sx={{marginTop:"20px"}}>
        <p style={{marginTop:"-0%",textAlign:"center",fontSize:"2.5rem",fontWeight:"600"}}  className="header">Showroom</p>
        <center>
          <Divider
            style={{
              padding: "0.5px",
              backgroundColor: "rgb(79 79 138)",
              width: "80px",
              marginTop: "-29px",
              marginBottom: "40px",
            }}
          />
        </center>
        <p style={{marginTop:"-2%",textAlign:"center",fontSize:"1.2rem",fontWeight:"600"}}>A Visual Journey Through Our World.</p>
      </Box>

      <Box >
      <Grid container spacing={2}  sx={{padding:"20px"}} textAlign={"center"}>
        {images.map((image, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
            <Box
              component="img"
              src={image}
              alt={`Gallery Image ${index + 1}`}
              sx={{
                width: {lg:"80%",md:"80%",xs:"95%",sm:"95%"},
                height: "auto",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            />
          </Grid>
        ))}
      </Grid>
      </Box>
      <Footer/>
    </Box>
  );
};
