import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Navbar } from "../components/Navbar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Define the validation schema using Yup
const schema = yup.object().shape({
  name: yup.string().required("Name is required").min(3,"name can't be less than 3"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
    contact: yup.string().required("Contact no. is required").matches(/^\d{10}$/, "Must be exactly 10 digits"),
});

export const PaymentPage = () => {
  // Initialize react-hook-form with Yup validation
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Handle form submission
  const onSubmit = (data) => {
    console.log(data);
    // Proceed with your payment logic here
    alert("Form submitted successfully!");
  };

  return (
    <Box
      style={{
        overflowX: "hidden",
        backgroundColor: "whitesmoke",
        color: "#113",
        fontFamily: "math",
      }}
    >
      <Helmet>
        <title>Lyss Technology Pvt. Ltd. | Payment</title>
      </Helmet>
      <Navbar />
      <Box
        sx={{
          marginTop: "70px",
          width: "100vw",
          textAlign: "center",
          backgroundImage: `url(../images/payment.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          paddingTop: { xs: "8vw", lg: "2vw", md: "2vw" },
          paddingBottom: "15vw",
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Overlay with opacity
            zIndex: 1,
          },
        }}
      >
        <Grid
          container
          sx={{
            position: "relative",
            zIndex: 2,
            color: "white",
            padding: { xs: "20px", sm: "20px", md: "50px" },
          }}
        >
          <Grid item xs={12} sm={12} lg={6} md={6}>
            <Typography
              variant="h1"
              sx={{
                fontSize: {
                  xs: "2rem",
                  sm: "2.4rem",
                  md: "2.6rem",
                  lg: "2.6rem",
                },
                marginTop: { xs: "20px", md: "80px" },
                fontWeight: "bold",
              }}
              id="hero"
            >
              Payment
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1.1rem",
                  md: "1.2rem",
                  lg: "1.2rem",
                },
                marginTop: "10px",
                fontWeight: "500",
                padding: { xs: "10px", sm: "10px", md: "0px" },
              }}
              id="hero1"
            >
              Your payment is safe and secure with industry-leading encryption
              and protection measures. We prioritize your privacy and ensure
              that all transactions are processed securely.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} md={6}></Grid>
        </Grid>
      </Box>
      <Container maxWidth="md" style={{marginBottom:"20%"}}>
        <Box mt={4} p={3} borderColor="grey.300" borderRadius={5}>
          <Typography
            variant="body1"
            align="center"
          
            fontSize="1.2rem"
          >
            Secure Payment Process
          </Typography>
          <center>
          <Divider style={{padding:"1px",backgroundColor:"rgb(79 79 138)",width:"100px",marginBottom:"8%",marginTop:"10px"}}/>
          </center>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Name"
                  type="text"
                  variant="outlined"
                  {...register("name")}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Email ID"
                  variant="outlined"
                  type="email"
                  {...register("email")}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="number"
                  fullWidth
                  label="contact no."
                  variant="outlined"
                  {...register("contact")}
                  error={!!errors.contact}
                  helperText={errors.contact?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Payment Amount"
                  variant="outlined"
                  value="₹100.00" // Amount should be fetched or calculated from the backend
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  sx={{
                    width: {lg:"50%",md:"50%",sm:"50%",xs:"90%"},
                    backgroundColor: "#833AB4", // PhonePe color
                    color: "#ffffff",
                    marginTop:"20px"
                  }}
                  variant="contained"
                  type="submit"
                >
                 Proceed to Pay
                </Button>
                <p style={{marginTop:"9px"}}>The safer,easier way to pay.</p>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};
