import React from "react";
import {
  Typography,
  Box,
  Grid,
  Container,
  Divider,
} from "@mui/material";
import { Navbar } from "../components/Navbar";
import Footer from "../components/Footer";

export const TermsConditions = () => {
  return (
    <Box sx={{ overflowX: "hidden", fontFamily: "Roboto, sans-serif" }}>
      <Navbar />

      <Box
        sx={{
          marginTop: "70px",
          width: "100vw",
          textAlign: "center",
          backgroundImage: `url(../images/banner3.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          paddingTop: { xs: "8vw", lg: "2vw", md: "2vw" },
          paddingBottom: "15vw",
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.6)", // Overlay with opacity
            zIndex: 1,
          },
        }}
      >
        <Grid
          container
          sx={{
            position: "relative",
            zIndex: 2,
            color: "white",
            padding: { xs: "20px", sm: "20px", md: "50px" },
          }}
        >
          <Grid item xs={12} sm={12} lg={6} md={6}>
            <Typography
              variant="h1"
              sx={{
                fontSize: {
                  xs: "2rem",
                  sm: "2.4rem",
                  md: "2.6rem",
                  lg: "2.6rem",
                },
                marginTop: { xs: "20px", md: "80px" },
                fontWeight: "bold",
              }}
            >
              Terms & Condition
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1.1rem",
                  md: "1.2rem",
                  lg: "1.2rem",
                },
                marginTop: "10px",
                fontWeight: "500",
                padding: { xs: "10px", sm: "10px", md: "0px" },
              }}
            >
              A strong Terms & Condition is not just a safety net for
              customers—it's a reflection of a company's confidence in the
              quality of its products and services.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} md={6}></Grid>
        </Grid>
      </Box>

      <Container
        maxWidth="md"
        sx={{ paddingTop: "2rem", paddingBottom: "2rem", textAlign: "justify" }}
      >
        <Box
          sx={{
            padding: "1rem",
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: 1,
          }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Terms & Condition
          </Typography>

          <Divider />

          <Box mt={2} mb={4}>
            <Typography variant="body1" paragraph>
              <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li style={{ marginBottom: "18px" }}>
                  This document is an electronic record in terms of Information
                  Technology Act, 2000 and rules there under as applicable and
                  the amended provisions pertaining to electronic records in
                  various statutes as amended by the Information Technology Act,
                  2000. This electronic record is generated by a computer system
                  and does not require any physical or digital signatures.
                </li>
                <li style={{ marginBottom: "18px" }}>
                  This document is published in accordance with the provisions
                  of Rule 3 (1) of the Information Technology (Intermediaries
                  guidelines) Rules, 2011 that require publishing the rules and
                  regulations, privacy policy and Terms of Use for access or
                  usage of domain name https://lyss.in ('Website'), including
                  the related mobile site and mobile application (hereinafter
                  referred to as 'Platform').
                </li>
                <li style={{ marginBottom: "18px" }}>
                  The Platform is owned by LYSS TECHNOLOGY PRIVATE LIMITED, a
                  company incorporated under the Companies Act, 1956 with its
                  registered office at H No 3 365 Lakho Binda Campus, Santu
                  nagar, Madhubani, Bihar (hereinafter referred to as 'Platform
                  Owner', 'we', 'us', 'our').
                </li>
                <li style={{ marginBottom: "18px" }}>
                  Your use of the Platform and services and tools are governed
                  by the following terms and conditions ('Terms of Use') as
                  applicable to the Platform including the applicable policies
                  which are incorporated herein by way of reference. If You
                  transact on the Platform, You shall be subject to the policies
                  that are applicable to the Platform for such transaction. By
                  mere use of the Platform, You shall be contracting with the
                  Platform Owner and these terms and conditions including the
                  policies constitute Your binding obligations, with Platform
                  Owner. These Terms of Use relate to your use of our website,
                  goods (as applicable) or services (as applicable)
                  (collectively, 'Services'). Any terms and conditions proposed
                  by You which are in addition to or which conflict with these
                  Terms of Use are expressly rejected by the Platform Owner and
                  shall be of no force or effect. These Terms of Use can be
                  modified at any time without assigning any reason. It is your
                  responsibility to periodically review these Terms of Use to
                  stay informed of updates.
                </li>
                <li style={{ marginBottom: "18px" }}>
                  For the purpose of these Terms of Use, wherever the context so
                  requires 'you', 'your' or 'user' shall mean any natural or
                  legal person who has agreed to become a user/buyer on the
                  Platform. ACCESSING, BROWSING OR OTHERWISE USING THE PLATFORM
                  INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER
                  THESE TERMS OF USE, SO PLEASE READ THE TERMS OF USE CAREFULLY
                  BEFORE PROCEEDING. The use of Platform and/or availing of our
                  Services is subject to the following Terms of Use:
                </li>
                <li style={{ marginBottom: "18px" }}>
                  To access and use the Services, you agree to provide true,
                  accurate and complete information to us during and after
                  registration, and you shall be responsible for all acts done
                  through the use of your registered account on the Platform.
                  Neither we nor any third parties provide any warranty or
                  guarantee as to the accuracy, timeliness, performance,
                  completeness or suitability of the information and materials
                  offered on this website or through the Services, for any
                  specific purpose. You acknowledge that such information and
                  materials may contain inaccuracies or errors and we expressly
                  exclude liability for any such inaccuracies or errors to the
                  fullest extent permitted by law.
                </li>
                <li style={{ marginBottom: "18px" }}>
                  Your use of our Services and the Platform is solely and
                  entirely at your own risk and discretion for which we shall
                  not be liable to you in any manner. You are required to
                  independently assess and ensure that the Services meet your
                  requirements. The contents of the Platform and the Services
                  are proprietary to us and are licensed to us. You will not
                  have any authority to claim any intellectual property rights,
                  title, or interest in its contents. The contents includes and
                  is not limited to the design, layout, look and graphics.
                </li>
                <li style={{ marginBottom: "18px" }}>
                  You acknowledge that unauthorized use of the Platform and/or
                  the Services may lead to action against you as per these Terms
                  of Use and/or applicable laws. You agree to pay us the charges
                  associated with availing the Services. You agree not to use
                  the Platform and/ or Services for any purpose that is
                  unlawful, illegal or forbidden by these Terms, or Indian or
                  local laws that might apply to you. You agree and acknowledge
                  that website and the Services may contain links to other third
                  party websites. On accessing these links, you will be governed
                  by the terms of use, privacy policy and such other policies of
                  such third party websites. These links are provided for your
                  convenience for providing further information.
                </li>
                <li style={{ marginBottom: "18px" }}>
                  You understand that upon initiating a transaction for availing
                  the Services you are entering into a legally binding and
                  enforceable contract with the Platform Owner for the Services.
                  You shall indemnify and hold harmless Platform Owner, its
                  affiliates, group companies (as applicable) and their
                  respective officers, directors, agents, and employees, from
                  any claim or demand, or actions including reasonable
                  attorney's fees, made by any third party or penalty imposed
                  due to or arising out of Your breach of this Terms of Use,
                  privacy Policy and other Policies, or Your violation of any
                  law, rules or regulations or the rights (including
                  infringement of intellectual property rights) of a third
                  party. Notwithstanding anything contained in these Terms of
                  Use, the parties shall not be liable for any failure to
                  perform an obligation under these Terms if performance is
                  prevented or delayed by a force majeure event.
                </li>
                <li style={{ marginBottom: "18px" }}>
                  These Terms and any dispute or claim relating to it, or its
                  enforceability, shall be governed by and construed in
                  accordance with the laws of India. All disputes arising out of
                  or in connection with these Terms shall be subject to the
                  exclusive jurisdiction of the courts in Madhubani and Bihar.
                  All concerns or communications relating to these Terms must be
                  communicated to us using the contact information provided on
                  this website.
                </li>
              </ul>
            </Typography>
          </Box>
        </Box>
      </Container>

      <Footer />
    </Box>
  );
};
